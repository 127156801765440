import { cn } from '@/lib/utils';
import { Pacifico } from 'next/font/google';

const pacifico = Pacifico({
    weight: '400',
    subsets: ['latin'],
})
const gradients = {
    sunset: 'bg-linear-to-r from-yellow-300 to-pink-500',
    ocean: 'bg-linear-to-r from-cyan-400 to-blue-600',
    forest: 'bg-linear-to-r from-emerald-400 to-teal-800',
    autumn: 'bg-linear-to-r from-amber-400 to-red-700',
    lavender: 'bg-linear-to-r from-purple-300 to-pink-600',
    neon: 'bg-linear-to-r from-green-400 to-purple-700',
    cosmic: 'bg-linear-to-r from-indigo-700 to-pink-600',
    sunrise: 'bg-linear-to-r from-red-400 to-yellow-400',
    northern_lights: 'bg-linear-to-r from-green-400 to-purple-700',
    tropical: 'bg-linear-to-r from-yellow-400 to-blue-600',
    fire_ice: 'bg-linear-to-r from-red-400 to-blue-600',
    desert: 'bg-linear-to-r from-yellow-300 to-red-600',
    rainbow: 'bg-linear-to-r from-red-500 to-purple-600',
    midnight: 'bg-linear-to-r from-blue-800 to-indigo-800',
    cherry_blossom: 'bg-linear-to-r from-pink-400 to-indigo-500',
    golden_hour: 'bg-linear-to-r from-yellow-300 to-red-500',
};


interface LogoProps {
    gradient?: keyof typeof gradients;
    textSize?: string;
    className?: string;
}

export default function Logo({ gradient = 'golden_hour', textSize = '3xl', className }: LogoProps) {
    const selectedGradient = gradients[gradient];

    return (
        <span className={cn(
            'text-white font-bold',
            `text-${textSize}`,
            pacifico.className,
            selectedGradient,
            'bg-clip-text text-transparent',
            'drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]',
            className
        )}>
            Readry
        </span>
    )
}